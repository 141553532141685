import { LAYOUT } from '@admin/router/constant'

// oms
const OmsRouter = {
  path: '/oms',
  name: '',
  component: LAYOUT,
  children: [
    // 订单管理
    {
      path: '/oms/order-manage/orderConfig',
      name: 'OMSCommonOrderConfig',
      component: () => import('@admin/views/oms/orderManage/orderConfig/index.vue'),
      meta: { helpUrl: '', title: '订单通用配置' },
    },
    {
      path: '/oms/order-manage/order-list',
      name: 'OmsOrderManageOrderList',
      component: () => import('@admin/views/oms/orderManage/order/index.vue'),
      meta: {
        title: '订单列表',
      },
    },
    {
      path: '/oms/order-manage/order-detail',
      name: 'OMSOrderManageOrderDetail',
      component: () => import('@admin/views/oms/orderManage/order/detail.vue'),
      meta: {
        title: '订单详情',
      },
    },
    {
      path: '/oms/order-manage/after-sales',
      name: '',
      component: () => import('@admin/views/oms/orderManage/afterSalesV2/index.vue'),
      meta: { helpUrl: '', title: '售后单管理' },
    },
    {
      path: '/oms/order-manage/after-sales-old',
      name: 'OMSafterSalesV2',
      component: () => import('@admin/views/oms/orderManage/afterSales/index.vue'),
      meta: { helpUrl: '', title: '售后单管理(旧)' },
    },
    {
      path: '/oms/order-manage/after-sales/detail',
      name: '',
      component: () => import('@admin/views/oms/orderManage/afterSalesV2/detail/index.vue'),
      meta: { helpUrl: '', title: '售后单详情' },
    },
    {
      path: '/oms/order-manage/after-sales/create',
      name: '',
      component: () => import('@admin/views/oms/orderManage/afterSalesV2/create.vue'),
      meta: { helpUrl: '', title: '创建售后单' },
    },
    {
      path: '/oms/order-manage/after-sales/preview',
      name: '',
      component: () => import('@admin/views/oms/orderManage/afterSales/components/pdfView.vue'),
      meta: { helpUrl: '', title: '查看物流面单' },
    },
    {
      path: '/oms/order-manage/split-order-decision',
      name: 'OMSSplitOrderDecision',
      component: () => import('@admin/views/oms/orderManage/splitOrderDecision/index.vue'),
      meta: { helpUrl: '', title: '拆单决策' },
    },
    {
      path: '/oms/order-manage/split-order-decision/edit',
      name: 'OMSSplitOrderDecisionEdit',
      component: () => import('@admin/views/oms/orderManage/splitOrderDecision/edit.vue'),
      meta: { helpUrl: '', title: '拆单处理' },
    },
    {
      path: '/oms/order-manage/split-order-decision/dateline',
      name: 'OMSSplitOrderDecisionDateLine',
      component: () => import('@admin/views/oms/orderManage/splitOrderDecision/dateline.vue'),
      meta: { helpUrl: '', title: '时间线查看' },
    },
    {
      path: '/oms/order-manage/order-shunt',
      name: 'OmsOrderManageOrderShunt',
      component: () => import('@admin/views/oms/orderManage/orderShunt/index.vue'),
      meta: { helpUrl: '', title: '订单手动分流' },
    },
    // 分配规则
    {
      path: '/oms/rule-engine/allocation-list',
      name: 'OMSRuleEngineAllocation',
      component: () => import('@admin/views/oms/ruleEngine/allocationRule/index.vue'),
      meta: {
        title: '库存分配规则引擎',
      },
    },
    {
      path: '/oms/rule-engine/split-order-list',
      name: 'OMSRuleEngineSplitOrder',
      component: () => import('@admin/views/oms/ruleEngine/splitOrderRule/index.vue'),
      meta: {
        title: '自动拆单规则引擎',
      },
    },
    {
      path: '/oms/rule-engine/order-detection-list',
      name: 'OMSRuleEngineOrderDetection',
      component: () => import('@admin/views/oms/ruleEngine/orderDetectionRule/index.vue'),
      meta: {
        title: '订单检测规则引擎',
      },
    },
    {
      path: '/oms/abnormally/list',
      name: 'OMSAbnormallyList',
      component: () => import('@admin/views/oms/abnormally/list/index.vue'),
      meta: {
        title: '异常单列表',
      },
    },
    // {
    //   path: '/oms/rule-engine/allocation-detail',
    //   name: 'OMSRuleEngineAllocationDetail',
    //   component: () => import('@admin/views/oms/ruleEngine/allocationRule/detail.vue'),
    //   meta: {
    //     title: '库存分配规则引擎详情',
    //   },
    // },
  ],
}

export default OmsRouter
