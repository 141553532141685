import { LAYOUT } from '@admin/router/constant'

// 仓内作业
const warehouseWorkRouter = {
  path: '/warehouse-work',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/warehouse-work/inventory-move',
      name: 'inventoryMove',
      component: () => import('@admin/views/wms/warehouseWork/inventoryMove/index.vue'),
      meta: { title: '仓内库存移动' },
    },
    {
      path: '/transfer/transfer-order',
      name: 'transferOrder',
      component: () => import('@admin/views/wms/warehouseWork/transfer/order/index.vue'),
      meta: { title: '调拨单' },
    },
    {
      path: '/transfer/transfer-order-edit',
      name: 'transferOrderEdit',
      component: () => import('@admin/views/wms/warehouseWork/transfer/order/edit.vue'),
      meta: { title: '编辑调拨单' },
    },
    {
      path: '/transfer/transfer-order-detail',
      name: 'transferOrderDetail',
      component: () => import('@admin/views/wms/warehouseWork/transfer/order/detail.vue'),
      meta: { title: '调拨单详情' },
    },
    {
      path: '/transfer/transfer-shipment-order',
      name: 'transferShipmentOrder',
      component: () => import('@admin/views/wms/warehouseWork/transfer/shipmentOrder/index.vue'),
      meta: { title: '调拨发货单' },
    },
    {
      path: '/transfer/transfer-shipment-order-detail',
      name: 'transferShipmentOrderDetail',
      component: () => import('@admin/views/wms/warehouseWork/transfer/shipmentOrder/detail.vue'),
      meta: { title: '调拨发货单详情' },
    },
    {
      path: '/transfer/transfer-received-order',
      name: 'transferDeliveryOrder',
      component: () => import('@admin/views/wms/warehouseWork/transfer/receivedOrder/index.vue'),
      meta: { title: '调拨收货单' },
    },
    {
      path: '/transfer/transfer-received-desk',
      name: 'transferReceivedDesk',
      component: () => import('@admin/views/wms/warehouseWork/transfer/receivedDesk/index.vue'),
      meta: { title: '调拨收货台' },
    },
    {
      path: '/transfer/transfer-onshelf-order',
      name: 'transferOnshelfOrder',
      component: () => import('@admin/views/wms/warehouseWork/transfer/onshelfOrder/index.vue'),
      meta: { title: '调拨上架单' },
    },
    {
      path: '/transfer/packing-list',
      name: 'transferPackingList',
      component: () => import('@admin/views/wms/warehouseWork/transfer/packingList/index.vue'),
      meta: { title: '装箱单列表' },
    },
    {
      path: '/transfer/packing-scan',
      name: 'transferPackingScan',
      component: () => import('@admin/views/wms/warehouseWork/transfer/packingList/scanSku.vue'),
      meta: { title: '装箱单装填' },
    },
    {
      path: '/warehouse-work/inventory-check-sheet',
      name: 'inventoryCheckSheet',
      component: () => import('@admin/views/wms/warehouseWork/inventoryCheckSheet/index.vue'),
      meta: { title: '盘点单' },
    },
    {
      path: '/wms/manual-stock-movement',
      name: 'manualStockMovement',
      component: () => import('@admin/views/wms/manualStockMovement/index.vue'),
      meta: { title: '手工出入库' },
    },
    {
      path: '/wms/manual-stock-movement-edit',
      name: 'editManualStockMovement',
      component: () => import('@admin/views/wms/manualStockMovement/edit.vue'),
      meta: { title: '编辑出入库单' },
    },
    {
      path: '/wms/manual-stock-movement-detail',
      name: 'manualStockMovementDetail',
      component: () => import('@admin/views/wms/manualStockMovement/detail.vue'),
      meta: { title: '出入库详情' },
    },
  ],
}

export default [warehouseWorkRouter]
