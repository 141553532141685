import { LAYOUT } from '@admin/router/constant'

// plm基础配置
const plmBasicConfigurationRouter = {
  path: '/plmBasicConfiguration',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/plm-basic-configuration/unit',
      name: 'Unit',
      component: () => import('@admin/views/plm/plmBasicConfiguration/unit/index.vue'),
      meta: { helpUrl: '', title: '单位' },
    },
    {
      path: '/plm-basic-configuration/color-configuration',
      name: 'plmBasicConfiguration',
      component: () =>
        import('@admin/views/plm/plmBasicConfiguration/colorConfiguration/index.vue'),
      meta: { helpUrl: '', title: '深衣颜色' },
    },
    {
      path: '/plm-basic-configuration/sizing-information',
      name: 'sizingInformation',
      component: () => import('@admin/views/plm/plmBasicConfiguration/sizingInformation/index.vue'),
      meta: { helpUrl: '', title: '尺码信息' },
    },
    {
      path: '/plm-basic-configuration/commodity-design-attribute',
      name: 'commodityDesignAttribute',
      component: () =>
        import('@admin/views/plm/plmBasicConfiguration/commodityDesignAttribute/index.vue'),
      meta: { helpUrl: '', title: '商品设计属性' },
    },
    {
      path: '/plm-basic-configuration/product-processing-technology',
      name: 'productProcessingTechnology',
      component: () =>
        import('@admin/views/plm/plmBasicConfiguration/productProcessingTechnology/index.vue'),
      meta: { helpUrl: '', title: '成品加工工艺' },
    },
  ],
}

export default plmBasicConfigurationRouter
