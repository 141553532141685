import { LAYOUT } from '@admin/router/constant'

// 新款设计
const plmNewDesignRouter = {
  path: '/plmNewDesign',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/new-design/node-list',
      name: 'newDesignNodeList',
      component: () => import('@admin/views/plm/newDesign/nodeList/index.vue'),
      meta: { helpUrl: '', title: '节点列表' },
    },
    {
      path: '/new-design/node-detail',
      name: 'newDesignNodeDetail',
      component: () => import('@admin/views/plm/newDesign/nodeDetail/index.vue'),
      meta: { helpUrl: '', title: '节点详情' },
    },
    {
      path: '/new-design/index',
      name: 'NewDesignIndex',
      component: () => import('@admin/views/plm/newDesign/index/index.vue'),
      meta: { helpUrl: '', title: '新款设计列表' },
    },
    {
      path: '/new-design/detail',
      name: 'NewDesignDetail',
      component: () => import('@admin/views/plm/newDesign/detail/index.vue'),
      meta: { helpUrl: '', title: '单据详情' },
    },
    {
      path: '/new-design/process-node',
      name: 'NewDesignNodeDetail',
      component: () => import('@admin/views/plm/newDesign/processNode/index.vue'),
      meta: { helpUrl: '', title: '工作流节点' },
    },
  ],
}

export default plmNewDesignRouter
