import { LAYOUT } from '@admin/router/constant'

// 进销存
const purchaseSaleInventoryRouter = {
  path: '/purchase-sale-inventory',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/wms/purchase-sale-inventory/list',
      name: 'wmsPurchaseSaleInventoryList',
      component: () => import('@admin/views/wms/purchaseSaleInventory/list/index.vue'),
      meta: { title: '进销存看板' },
    },
  ],
}

export default [purchaseSaleInventoryRouter]
