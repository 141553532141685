import { LAYOUT } from '@admin/router/constant'

// 新款设计
const materialDevelopmentSheetRouter = {
  path: '/materialDevelopmentSheet',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/material-development-sheet/index',
      name: 'materialDevelopmentSheetList',
      component: () => import('@admin/views/plm/materialDevelopmentSheet/index/index.vue'),
      meta: { helpUrl: '', title: '物料开发单' },
    },
    {
      path: '/material-development-sheet/detail',
      name: 'materialDevelopmentSheetDetail',
      component: () => import('@admin/views/plm/newDesign/detail/index.vue'),
      meta: { helpUrl: '', title: '物料开发详情' },
    },
  ],
}

export default materialDevelopmentSheetRouter
