import { LAYOUT } from '@admin/router/constant'

// plm工作流设置
const plmWorkflowSetupRouter = {
  path: '/plmWorkflowSetup',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/workflowSetup',
      name: 'workflowList',
      component: () => import('@admin/views/plm/plmWorkflow/workflowSetup/list/index.vue'),
      meta: { helpUrl: '', title: '工作流设置' },
    },
    {
      path: '/workflowSetup/workflow-detail',
      name: 'workflowDetail',
      component: () => import('@admin/views/plm/plmWorkflow/workflowSetup/detail/index.vue'),
      meta: { helpUrl: '', title: '工作流详情' },
    },
  ],
}

export default plmWorkflowSetupRouter
